import {getHttpClient} from './client'

export const getAllCompanies = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/company")
        .then(response => response.data)
}

export const getCompany = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/company/" + data)
        .then(response => response.data)
}

export const saveCompany = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/company", 'POST', data)
        .then(response => response.data);
}

export const updateCompany = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/company", 'POST', data)
        .then(response => response.data);
}

export const deleteCompany = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/company/" + data.id, 'DELETE', data)
        .then(response => response.data);
}
