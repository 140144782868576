<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" sm="12" md="6">
        <v-card>
          <v-card-title>
            Broj prevoza po vozilima
          </v-card-title>
          <v-card-text>
            <pie-chart
                :backgroundColors="backgroundColors"
                :labels="invoiceVehicleUsageData"
                :data="invoiceVehicleUsageLabels"
            ></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col  class="mb-5" cols="auto" sm="12" md="6">
        <v-card>
          <v-card-title>
            Broj prevoza po zaposlenima
          </v-card-title>
          <v-card-text>
            <pie-chart
                :backgroundColors="backgroundColors"
                :labels="invoiceUsersLabels"
                :data="invoiceUsersData"
            ></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mb-5" cols="auto" sm="12" md="6">
        <v-card>
          <v-card-title>
            Broj prevoza po klijentima
          </v-card-title>
          <v-card-text>
            <pie-chart
                :backgroundColors="backgroundColors"
                :labels="invoiceCustomersLabels"
                :data="invoiceCustomersData"
            ></pie-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PieChart from "@/components/PieChart";
import {colors} from "@/mixins/background-colors";

export default {
  name: "StatisticsPage",
  components: {
    PieChart,
  },
  data: () =>({
    backgroundColors: [],
    invoiceVehicleUsageData: [],
    invoiceVehicleUsageLabels: [],
    invoiceUsersData: [],
    invoiceUsersLabels: [],
    invoiceCustomersData: [],
    invoiceCustomersLabels: [],
  }),
  created() {
    this.backgroundColors = colors
    this.loadPieChartData();
  },
  methods: {
    async loadPieChartData(){
      await this.$store.dispatch('charts/getInvoicesVehicleUsage').then((res) => {
        res.data.forEach(el => {
          this.invoiceVehicleUsageData.push(el.vehicle?.name || 'N/A');
          this.invoiceVehicleUsageLabels.push(el.total);
        })
      })
      await this.$store.dispatch('charts/getInvoicesCustomers').then((res) => {
        res.data.forEach(el => {
          this.invoiceCustomersLabels.push(el.customer?.name || 'N/A');
          this.invoiceCustomersData.push(el.total);
        })
      })
      await this.$store.dispatch('charts/getInvoicesUsers').then((res) => {
        res.data.forEach(el => {
          this.invoiceUsersLabels.push(el.user?.name || 'N/A');
          this.invoiceUsersData.push(el.total);
        })
      })
    },
  }
}
</script>

<style scoped>

</style>