<template>
  <v-app-bar
      app
      class="primary"
      dark
  >
    <div class="d-flex align-center">
      <v-img
          alt="Logo"
          class="shrink mr-2"
          contain
          src="../assets/logo.png"
          transition="scale-transition"
          width="60"
      />
    </div>
    <h2>
      TruckMate
    </h2>
  </v-app-bar>
</template>

<script>

export default {
  name: 'HeaderEmpty',
  data() {
    return {}
  }
}

</script>
