import {getHttpClient} from './client'

export const getAllInvoices = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + `/invoice`)
        .then(response => response.data)
}

export const getInvoice = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice/" + data)
        .then(response => response.data)
}

export const saveInvoice = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice", 'POST', data)
        .then(response => response.data);
}

export const updateInvoice = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice", 'POST', data)
        .then(response => response.data);
}

export const deleteInvoice = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice/" + data.id, 'DELETE', data)
        .then(response => response.data);
}

export const getInvoiceNumber = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice-number")
        .then(response => response.data);
}

export const downloadInvoice = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/download-invoice/" + data.id)
        .then(response => response.data);
}
