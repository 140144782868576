import {getHttpClient} from './client'

export const getInvoicesVehicleUsage = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle-usage")
        .then(response => response.data)
}

export const getInvoicesUsers = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice-users")
        .then(response => response.data)
}

export const getInvoicesCustomers = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/invoice-customers")
        .then(response => response.data);
}
