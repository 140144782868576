import {deleteInvoice, getAllInvoices, getInvoice, getInvoiceNumber, saveInvoice, updateInvoice, downloadInvoice} from "@/api/invoices";

export default {
    namespaced: true,
    mutations: {},

    actions: {
        async getAllInvoices(state, item) {
            return getAllInvoices(item);
        },
        async getInvoice(state, item) {
            return getInvoice(item);
        },
        async saveInvoice(state, item) {
            return saveInvoice(item);
        },
        async updateInvoice(state, item) {
            return updateInvoice(item);
        },
        async deleteInvoice(state, item) {
            return deleteInvoice(item);
        },
        async getInvoiceNumber() {
            return getInvoiceNumber();
        },
        async downloadInvoice(state, item) {
            return downloadInvoice(item)
        }
    },
    getters: {},
};
