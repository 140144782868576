export default {
    namespaced: true,
    mutations: {},
    actions: {
        errorMapper(state, item) {
            const data = {
                'VEHICLE-0001': 'Naziv vozila je obavezan',
                'VEHICLE-0002': 'Naziv vozila mora biti jedinstven',
                'VEHICLE-0003': 'Naziv vozila ne smije biti duži od 255 karaktera',
                'VEHICLE-0004': 'Unesite pravilan naziv vozila',
                'VEHICLE-0005': 'VEHICLE-0005',
                'COMPANY-0001': 'Naziv kompanije je obavezan',
                'COMPANY-0002': 'COMPANY-0002',
                'USER-0001': 'Ime i prezime korisnika su obavezni',
                'USER-0002': 'Unesite pravilno ime i prezime',
                'USER-0003': 'Korisničko ime je obavezno',
                'USER-0004': 'Email je obavezan',
                'USER-0005': 'Email mora biti pravilnog formate',
                'USER-0006': 'Uloga korisnika je obavezna',
                'USER-0007': 'USER-0007',
                'USER-0008': 'Uloga je obavezna',
                'USER-0009': 'Kompanija je obavezna',
                'USER-0010': 'USER-0010',
                'USER-0011': 'USER-0011',
                'INVOICE-0001': 'Naziv naloga je obavezan',
                'INVOICE-0002': 'Naziv naloga mora biti jedinstven',
                'INVOICE-0003': 'Vozilo je obavezno',
                'INVOICE-0004': 'Datum je obavezan',
                'INVOICE-0005': 'Klijent je obavezan',
                'INVOICE-0006': 'Korisnik je obavezan',
                'INVOICE-0007': 'Korisnik je obavezan',
                'INVOICE-0008': 'INVOICE-0008',
                'INVOICE-0009': 'Naziv mora biti kraći od 255 karaktera',
                'INVOICE-0010': 'Unesite pravilan naziv naloga',
                'INVOICE-0011': 'INVOICE-0011',
                'INVOICE-0012': 'Vozilo je obavezno',
                'CUSTOMER-0001': 'Naziv klijenta je obevezan',
                'CUSTOMER-0002': 'Naziv klijenta mora biti jedinstven',
                'CUSTOMER-0003': 'CUSTOMER-0003',
                'CUSTOMER-0004': 'Naziv klijenta ne smije biti duži od 255 karaktera',
                'CUSTOMER-0005': 'Unestie pravilan naziv klijenta',
                'AUTH-USERNAME-0001': 'Korisničko ime je obeveyzno',
                'AUTH-USERNAME-0002': 'Unesite pravilno korisničko ime',
                'AUTH-USERNAME-0003': 'Korisničko ime ne postoji',
                'AUTH-PASS-0001': 'Lozinka je obevezna',
            }

            return data[item] || 'Došlo je do greške, prijavite podršci';
        },
    },
    getters: {},
};