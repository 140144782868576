export const fieldRules =
    {
        usernameRules: [
            v => (!!v && !!v.trim()) || 'Korisnicko ime je obavezno',
        ],

        imageRules: [
            v => !!v || 'Slika je obavezna',
        ],
        positiveNumberRules: [
            v => Number.isInteger(v) || 'Broj titula mora biti pozitivan cijeli broj ili nula',
            v => v >= 0 || 'Broj titula ne može biti negativan broj',
        ],
        personalNameRules: [
            v => (!!v && !!v.trim()) || 'Ime i prezime su obavezni',
        ],
        nameRules: [
            v => (!!v && !!v.trim()) || 'Naziv je obavezan',
        ],
        seasonRules: [
            v => !!v || 'Sezona je obavezna',
        ],
        nameMax255CharsRules: [
            v => !!v || 'Naziv je obavezan',
            v => v ? v.length <= 255 || 'Najviše 255 karaktera' : false,
        ],
        dateRules: [
            v => !!v || 'Datum je obavezan',
        ],
        endDateRules: {
            compare(startDate) {
                if (!startDate) {
                    return ['Datum početka kola je obavezan']
                }
                return [
                    v => v ? v >= startDate || 'Datum kraja ne može biti prije datuma početka' : true
                ]
            },
        },
        phoneRules: [
            v => !!v || 'Broj telefona je obavezan',
            v => /^[+]38[12] 6[1-9]\d{6}$/.test(v) || 'Broj telefona mora biti validnog formata',
        ],
        emailRules: [
            v => (!!v && !!v.trim()) || 'Email je obavezan',
            v => !!v || 'E-mail je obavezan',
            v => /.+@.+\..+/.test(v) || 'E-mail mora biti validnog formata',
        ],
        passwordRules: [
            v => !!v || 'Lozinka je obavezna',
            v => /.+/.test(v) || 'Lozinka ne može biti prazna',
        ],
        noteRules: [
            v => !!v || 'Napomena je obavezan',
        ],
        valueRules: [
            v => !!v || 'Vrijednost je obavezna',
        ],
        roleRules: [
            v => !!v || 'Uloga je obavezna',
        ],
        companyRules: [
            v => !!v || 'Kompanija je obavezna',
        ],
        addressRules: [
            v => !!v || 'Adresa je obavezna',
        ],
        cityRules: [
            v => !!v || 'Grad je obavezan',
        ],
        propertyTypeRules: [
            v => !!v || 'Tip je obavezna',
        ],
        titleRules: [
            v => (!!v && !!v.trim()) || 'Naziv je obavezan',
        ],
        fileRules: [
            v => !!v || 'Fajl je obavezan',
        ],
        typeRules: [
            v => !!v || 'Tip je obavezan',
        ],
        descriptionRules: [
            v => (!!v && !!v.trim()) || 'Opis je obavezan',
        ],
        descriptionMax1000CharsRules: [
            v => !!v || 'Opis je obavezan',
            v => v.length <= 1000 || 'Najviše 1000 karaktera',
        ],
        userRules: [
            v => !!v || 'Korisnik je obavezan',
        ],
        customerRules: [
            v => !!v || 'Partner je obavezan',
        ],
        max20Chars: [
            v => v ? v.length <= 20 || 'Najviše 20 karaktera' : false,
        ],
        max50Chars: [
            v => v ? v.length <= 50 || 'Najviše 50 karaktera' : false,
        ],
        max100Chars: [
            v => v ? v.length <= 100 || 'Najviše 100 karaktera' : false,
        ],
        max150Chars: [
            v => v ? v.length <= 150 || 'Najviše 150 karaktera' : false,
        ],
        max200Chars: [
            v => v ? v.length <= 200 || 'Najviše 200 karaktera' : true,
        ],
        max255Chars: [
            v => v ? v.length <= 255 || 'Najviše 255 karaktera' : false,
        ],
        max500Chars: [
            v => v ? v.length <= 500 || 'Najviše 500 karaktera' : false,
        ],
        max1000Chars: [
            v => v ? v.length <= 1000 || 'Najviše 1000 karaktera' : true,
        ],
        positiveValue: [
            v => (v >= 0) || 'Vrijednost mora biti pozitivna',
        ],
        max4MBLimit: [
            v => !v || v.size < 4500000 || 'Fajl mora biti manji od 4 MB!',
        ],
        max1000CharsNotRequired:
            [
                v => v ? v.length <= 1000 || 'Najviše 1000 karaktera' : true,
            ],
        fileRequireAndTypeRules: {
            check(id, file) {
                if (!id && !file) {
                    return ['Slika je obavezna']
                } else if (file) {
                    if (file.size >= 4500000){
                        return ['Slika mora biti manja od 4MB']
                    }
                    let allowedTypes = ['jpg', 'jpeg', 'png'];
                    let extension = file.name.split('.').pop()
                    if (allowedTypes.indexOf(extension) === -1) {
                        return [
                            'Dozvoljene ekstenzije slike: ' + allowedTypes.join(', .')
                        ]
                    } else {
                        return []
                    }
                }
            }
        }
    }
