import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import StatisticsPage from "@/views/pages/StatisticsPage";

Vue.use(VueRouter)
const routePrefix = process.env.VUE_APP_ROUTE_PREFIX

const routes = [
    {
        path: routePrefix + '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomePage'),
        meta: {
            auth: true,
            roles: [2]
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login'),
        meta: {
            auth: false
        }
    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        component: () => import(/* webpackChunkName: "vehicles" */ '../views/pages/VehiclesPage'),
        meta: {
            auth: true,
            roles: [2]
        }
    },
    {
        path: '/companies',
        name: 'Companies',
        component: () => import(/* webpackChunkName: "companies" */ '../views/pages/CompaniesPage'),
        meta: {
            auth: true,
            roles: [1]
        }
    },
    {
        path: '/partners',
        name: 'Partners',
        component: () => import(/* webpackChunkName: "partners" */ '../views/pages/ClientsPage'),
        meta: {
            auth: true,
            roles: [2]
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '../views/pages/UsersPage'),
        meta: {
            auth: true,
            roles: [1,2]
        }
    },
    {
        path: '/statistic',
        name: 'Statistic',
        component: StatisticsPage,
        meta: {
            auth: true,
            roles: [2]
        }
    },
]

export const rolesMap = [
    {
        role: 'masterAdmin',
        id: 1,
        redirect: 'Users'
    },
    {
        role: 'Admin',
        id: 2,
        redirect: 'Home'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    rolesMap
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        if (store.getters['auth/authToken'] && from.name !== to.name ) {
            const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']))
            let userHasRight = validateUserPermissions(to, userRole);
            if(!userHasRight){
                next({name: userRole.redirect});
            }
            next()
        } else {
            next({ name: 'Login' }); // if not auth then login
        }
    }
    else if (store.getters['auth/authToken']) {
        const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']))
        next({ name: userRole.redirect }); // if auth and try login, go home
    }
    else{
        next();
    }
})

function validateUserPermissions(to, userRole) {
    let final = false;
    routes.forEach((route) => {
        if (route.name === to.name && route.name !== 'Login') {
            if (route.meta.roles.includes(parseInt(userRole.id)))
                final = true
        } else if (route.to === 'Login') {
            final = true
        }
    })
    return final
}

export default router
