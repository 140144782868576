<template>
  <v-bottom-navigation class="info white--text" fixed padless>
    <v-col
        class="text-center"
        cols="12"
    >
      Powered by <a href="https://goldbear.me" id="powered-by" target="_blank"><strong>GoldBear Technologies d.o.o.</strong></a>
    </v-col>
  </v-bottom-navigation>
</template>

<script>

export default {
  name: 'FooterComponent'
}

</script>

<style>
#powered-by{
  text-decoration: none;
  color: white;
}
</style>
