import {getAllRoles} from "@/api/roles";


export default {
    namespaced: true,
    mutations: {},

    actions: {
        async getAllRoles() {
            return getAllRoles();
        },
    }
}