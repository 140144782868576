import {getHttpClient} from "@/api/client";

export const login = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/login", 'POST', data)
        .then(response => response.data);
}

export const saveUser = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/admin/save-user", 'POST', data)
        .then(response => response.data);
}

export const updateUser = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/update-user", 'POST', data)
        .then(response => response.data);
}
