import {getHttpClient} from './client'

export const getAllVehicles = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle")
        .then(response => response.data)
}

export const getVehicle = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle/" + data)
        .then(response => response.data)
}

export const saveVehicle = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle", 'POST', data)
        .then(response => response.data);
}

export const updateVehicle = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle", 'POST', data)
        .then(response => response.data);
}

export const deleteVehicle = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/vehicle/" + data.id, 'DELETE', data)
        .then(response => response.data);
}
