import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth'
import partners from "@/store/modules/partners";
import vehicles from "@/store/modules/vehicles";
import users from "@/store/modules/users";
import invoices from "@/store/modules/invoices";
import companies from "@/store/modules/companies";
import roles from "@/store/modules/roles";
import errorMessages from "@/store/modules/errorMessages";
import charts from "@/store/modules/statisticsCharts"

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
    state: {
        snackbar: {}
    },
    getters: {},
    mutations: {
        SET_SNACKBAR(state, snackbar) {
            state.snackbar = snackbar;
        }
    },
    actions: {
        showSnackbar({commit}, snackbar) {
            snackbar.active = true;
            commit('SET_SNACKBAR', snackbar);
        }
    },
    modules: {
        auth,
        partners,
        vehicles,
        users,
        invoices,
        companies,
        roles,
        errorMessages,
        charts
    }
})
