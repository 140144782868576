import {deleteCompany, getAllCompanies, getCompany, saveCompany, updateCompany,} from "@/api/companies";

export default {
    namespaced: true,
    mutations: {},
    actions: {
        async getAllCompanies() {
            return getAllCompanies();
        },
        async getCompany(state, item) {
            return getCompany(item);
        },
        async saveCompany(state, item) {
            return saveCompany(item);
        },
        async updateCompany(state, item) {
            return updateCompany(item);
        },
        async deleteCompany(state, item) {
            return deleteCompany(item);
        },
    },
    getters: {},
};
