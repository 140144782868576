import {deleteUser, getAllUsers, saveUser, updateUser,} from '@/api/users'

export default {
    namespaced: true,
    mutations: {},

    actions: {
        async getAllUsers() {
            return getAllUsers()
        },
        async saveUser(state, item) {
            return saveUser(item)
        },
        async updateUser(state, item) {
            return updateUser(item)
        },
        async deleteUser(state, item) {
            return deleteUser(item)
        },
    },
    getters: {}
}
