import {deleteVehicle, getAllVehicles, getVehicle, saveVehicle, updateVehicle,} from "@/api/vehicles";

export default {
    namespaced: true,
    mutations: {},
    actions: {
        async getAllVehicles() {
            return getAllVehicles();
        },
        async getVehicle(state, item) {
            return getVehicle(item);
        },
        async saveVehicle(state, item) {
            return saveVehicle(item);
        },
        async updateVehicle(state, item) {
            return updateVehicle(item);
        },
        async deleteVehicle(state, item) {
            return deleteVehicle(item);
        },
    },
    getters: {},
};
