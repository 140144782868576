<script>
// CommitChart.js
import {Pie} from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    title: {
      type: String,
      default: ''
    },
    backgroundColors: {
      type: Array,
      // default: ''
    },
    labels: {
      type: Array
    },
    data: {
      type: Array
    }
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    renderPieChart: function() {
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                label: "Data One",
                backgroundColor: this.backgroundColors,
                data: this.data
              }
            ]
          },
          { responsive: true, maintainAspectRatio: false }
      );
    }
  },
  watch: {
    data: function() {
      this.renderPieChart();
    }
  }
}
</script>
