<template>
  <v-container>

    <v-navigation-drawer
        v-model="drawer"
        app
    >
      <v-list>
        <v-list-item :to='routePrefix.concat("/profile")' link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle style="white-space: break-spaces">{{ user?.company?.name }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-if="user.role_id === 2">
          <side-menu-item
              :link='routePrefix.concat("/")'
              icon="mdi-file-document"
              title="Nalozi"
              tooltip="Nalozi - Svi nalozi"/>

          <side-menu-item
              :link='routePrefix.concat("/vehicles")'
              icon="mdi-car"
              title="Vozila"
              tooltip="Administracija vozila"/>

          <side-menu-item
              :link='routePrefix.concat("/partners")'
              icon="mdi-arrow-right"
              title="Klijenti"
              tooltip="Administracija klijenata"/>

          <side-menu-item
              :link='routePrefix.concat("/statistic")'
              icon="mdi-chart-bar"
              title="Statistika"
              tooltip="Statistika"/>
        </template>

        <side-menu-item v-if="user.role_id === 1"
                        :link='routePrefix.concat("/companies")'
                        icon="mdi-arrow-right"
                        title="Kompanije"
                        tooltip="Administracija kompanija"/>

        <side-menu-item
            :link='routePrefix.concat("/users")'
            icon="mdi-account"
            title="Administracija korisnika"
            tooltip="Administracija korisnika"/>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar app class="primary" dark>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="d-flex align-center" @click="this.redirectToHomePage">
                <v-img
                    alt="Logo"
                    class="shrink mr-2"
                    contain
                    src="../assets/logo.png"
                    transition="scale-transition"
                    width="60"/>
      </div>
      <h2>TruckMate</h2>

      <v-spacer></v-spacer>

      <v-dialog
          v-model="dialog"
          max-width="450"
          persistent
      >
        <template v-slot:activator="{ on: dialog }">
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                  text
                  v-on="{ ...tooltip, ...dialog }"
              >
                <v-icon>
                  mdi-logout
                </v-icon>
              </v-btn>
            </template>
            <span>Odjava iz sistema</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-card-title class="blue darken-1 title white--text font-weight-bold">
            Odjava iz sistema
          </v-card-title>
          <v-card-text>
            <v-container>

              Da li ste sigurni da želite da se odjavite iz sistema?
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="red darken-1 font-weight-bold"
                text
                @click="dialog = false"
            >
              Ne želim
            </v-btn>
            <v-btn
                color="green darken-1 font-weight-bold"
                text
                @click="logout"
            >
              Želim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-app-bar>

    <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
        :timeout="2000"
        bottom
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.active = false">
          Zatvori
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>
</template>

<script>

import SideMenuItem from './SideMenuItem'
import state from "../store";
import router from "../router";
import {fieldRules} from "@/mixins/field-rules";

export default {
  name: 'MainLayout',
  data() {
    return {
      user: {
        name: '',
        email: '',
        role_id: null
      },
      drawer: false,
      dialog: false,
      preview: true,
      menu: false,
      routePrefix: process.env.VUE_APP_ROUTE_PREFIX,
      fieldRules,
      snackbar: {
        active: false,
        color: '',
        text: ''
      }
    }
  },
  computed: {
    item_length: function (array) {
      return array.length;
    }
  },
  components: {
    SideMenuItem,
  },
  created() {
    this.loadUser()
    this.fieldRules = fieldRules
  },
  methods: {

    loadUser() {
      this.user = this.$store.getters['auth/user']
    },

    logout() {
      state.dispatch("auth/logout")
      this.dialog = false
      router.push({name: 'Login'})
    },

    redirectToHomePage() {
      router.push({name: 'Home'})
    },

    color(active) {
      return (active) ? 'green' : 'red';
    },
  }
}

</script>
