import {getInvoicesVehicleUsage, getInvoicesUsers, getInvoicesCustomers} from "@/api/statisticsCharts";

export default {
    namespaced: true,
    mutations: {},
    actions: {
        async getInvoicesVehicleUsage() {
            return getInvoicesVehicleUsage();
        },
        async getInvoicesUsers() {
            return getInvoicesUsers();
        },
        async getInvoicesCustomers() {
            return getInvoicesCustomers();
        },
    },
    getters: {},
};
