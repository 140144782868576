import {getHttpClient} from './client'

export const getAllUsers = async () => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/user")
        .then(response => response.data)
}

export const saveUser = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/user", 'POST', data)
        .then(response => response.data);
}

export const updateUser = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/user", 'POST', data)
        .then(response => response.data);
}

export const deleteUser = async (data) => {
    return await getHttpClient(process.env.VUE_APP_API_URL + "/user/" + data.id, 'DELETE', data)
        .then(response => response.data);
}
