<template>
  <v-app>
    <v-layout fill-height>

      <div v-if="this.$store.state.auth.authenticated">
        <main-layout/>
      </div>
      <div v-else>
        <HeaderEmpty/>
      </div>

      <v-main class="mb-16">
        <router-view :key="$route.fullPath" class="pr-10 mb-5"/>
      </v-main>

      <Footer/>

    </v-layout>
    <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
        :timeout="2000"
        bottom
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.active = false">
          Zatvori
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import HeaderEmpty from "./components/HeaderEmpty";
import Footer from "./components/Footer";
import MainLayout from "./components/MainLayout";
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {
    HeaderEmpty,
    Footer,
    MainLayout
  },
  data: () => ({}),
  computed: {
    ...mapState(['snackbar'])
  },
  created() {
    this.snackbar.active = false
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_BROWSER_TITLE,
      meta: [
        {name: 'description', content: 'TruckMate'},
        {property: 'og:title', content: "TruckMate"},
        {property: 'og:site_name', content: 'TruckMate'},
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    }
  }
}
</script>

<style>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .03);
}
</style>
